(function ($) {

    'use strict';

    // Document ready function

    $(document).ready(function () {

        $(document.body).trigger('wc_fragment_refresh');

        // Menu toggle
        $('body').on('click', '.header-style-2 .menu-toggle', function () {
            $('body').toggleClass('menu-toggle-active');
        });

        let countDownElements = $('.deal-coutdown');

        if ( countDownElements.length > 0 ) {

            countDownElements.each(function () {

                let countDownVal = $(this).parent().find('.date-holder').val();

                $(this).countdown(countDownVal, function (event) {

                    $(this).html(event.strftime('<div class="block days"><span class="clock">%D</span><span class="text">' + orchidStoreProJSObj.countDownLabels.days + '</span></div><div class="block hours"><span class="clock">%H</span><span class="text">' + orchidStoreProJSObj.countDownLabels.hours + '</span></div><div class="block minutes"><span class="clock">%M</span><span class="text">' + orchidStoreProJSObj.countDownLabels.minutes + '</span></div><div class="block seconds"><span class="clock">%S</span><span class="text">' + orchidStoreProJSObj.countDownLabels.seconds + '</span></div>'));
                });
            });
        }

        /*
        =======================
        = Owl carousels 
        ====================================
        */


        var ospOwlRtl;

        if (jQuery('body').hasClass('rtl')) {

            ospOwlRtl = true

        } else {

            ospOwlRtl = false;
        }


        // banner style 2 - pro version

        jQuery('.os-banner-carousel-2').owlCarousel({

            rtl: ospOwlRtl,
            items: 1,
            loop: true,
            lazyLoad: false,
            margin: 0,
            smartSpeed: 1000,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: false,
        });

        // Category carousel 5 - pro version

        jQuery('.owl-carousel-5').owlCarousel({

            rtl: ospOwlRtl,
            items: 6,
            loop: true,
            lazyLoad: false,
            margin: 30,
            smartSpeed: 700,
            nav: true,
            dots: false,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                400: {
                    items: 1
                },
                420: {

                    items: 2
                },
                480: {

                    items: 2
                },
                500: {

                    items: 2
                },
                600: {
                    items: 3
                },
                768: {
                    items: 4
                },
                850: {
                    items: 4
                },
                992: {
                    items: 5
                },
                1050: {

                    items: 5
                },
                1200: {
                    items: 6
                }
            },
        });


        // Blog posts style 2 - pro version

        jQuery('.blog-carousel-style-2').owlCarousel({

            rtl: ospOwlRtl,
            items: 3,
            loop: true,
            lazyLoad: false,
            margin: 30,
            smartSpeed: 800,
            nav: true,
            dots: false,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                500: {

                    items: 1
                },
                600: {
                    items: 1
                },
                768: {
                    items: 2
                },
                850: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1050: {

                    items: 3
                },
                1200: {
                    items: 3
                }
            },
        });


        // Brands carousel  - pro version

        jQuery('.os-brands-1').owlCarousel({

            rtl: ospOwlRtl,
            items: 5,
            loop: true,
            lazyLoad: false,
            margin: 30,
            smartSpeed: 200,
            nav: false,
            dots: false,
            autoplay: true,
            autoplayHoverPause: true,
            responsive: {

                0: {

                    items: 2
                },
                420: {

                    items: 2
                },
                500: {

                    items: 3
                },
                600: {
                    items: 3
                },
                768: {
                    items: 4
                },
                850: {
                    items: 4
                },
                992: {
                    items: 4
                },
                1050: {

                    items: 5
                },
                1200: {
                    items: 5
                }
            },
        });


        // Testimonial carousel - pro version


        jQuery('.os-testimonial-1').owlCarousel({

            rtl: ospOwlRtl,
            items: 2,
            loop: true,
            lazyLoad: false,
            margin: 30,
            smartSpeed: 1000,
            nav: false,
            dots: false,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                400: {

                    item: 1
                },
                500: {

                    items: 1
                },
                600: {
                    items: 1
                },
                768: {
                    items: 1
                },
                850: {
                    items: 2
                },
                992: {
                    items: 2
                },
                1050: {

                    items: 2
                },
                1200: {
                    items: 2
                }
            },
        });



        if (orchidStoreProJSObj.hasOwnProperty('ajaxAddToCart') && orchidStoreProJSObj.ajaxAddToCart === '1') {

            $(document).on('submit', '.single-product .entry-summary form.cart', function (event) {

                event.preventDefault();

                let addToCartForm = $(this);

                let productID = 0;
                let productType = 'simple';

                // Set class `loading` in the add to cart button.
                let addtoCartButton = addToCartForm.find('.single_add_to_cart_button');
                addtoCartButton.addClass('loading');

                let productTypeEle = $('#osp-bn-product-type');

                if (!jQuery.isEmptyObject(productTypeEle)) {
                    productType = productTypeEle.val();
                }

                if (productType === 'external') {
                    event.currentTarget.submit();
                    return;
                }

                let formData = {
                    type: '',
                    data: {},
                };

                // Prepare data for grouped form.
                if (productType === 'grouped') {
                    formData.type = 'grouped';
                    let allProductsRows = addToCartForm.find('.woocommerce-grouped-product-list-item');
                    if (allProductsRows.length > 0) {
                        allProductsRows.map((index, productRow) => {
                            if (productRow.classList.contains('product-type-simple')) {
                                let productRowID = productRow.id;
                                let productID = parseInt(productRowID.replace('product-', ''));
                                let quantity = 0;
                                if (productRow.classList.contains('sold-individually')) {
                                    let quantityEle = addToCartForm.find('#' + productRowID + ' input.wc-grouped-product-add-to-cart-checkbox');
                                    quantity = quantityEle.is(':checked') ? quantityEle.val() : 0;
                                } else {
                                    let quantityEle = addToCartForm.find('#' + productRowID + ' input.qty');
                                    quantity = quantityEle.val();
                                }

                                formData.data[productID] = (quantity === '') ? 0 : quantity;
                            }
                        });
                    }
                }

                // Prepare data for variation form.
                if (productType === 'variation') {
                    formData.type = 'variation';
                    productID = $('input[name="product_id"]').val();
                    formData.data.product_id = productID;
                    formData.data.variation_id = $('input[name="variation_id"]').val();
                    formData.data.quantity = $('input.qty').val();
                    let variations = {};
                    let variationEles = addToCartForm.find('table.variations td.value select');
                    if (variationEles.length > 0) {
                        variationEles.map((index, variationEle) => {
                            let variationName = variationEle.getAttribute('name');
                            let variationValue = variationEle.value;
                            variations[variationName] = variationValue;
                        });
                    }
                    formData.data.variations = variations;
                }

                // Prepare data for simple form.
                if (!addToCartForm.hasClass('variations_form') && !addToCartForm.hasClass('grouped_form')) {
                    formData.type = 'simple';
                    productID = addtoCartButton.val();
                    formData.data.product_id = productID;
                    formData.data.quantity = $('.quantity input.qty').val();
                }

                // Make AJAX request and handle response.
                $.ajax({
                    'url': orchidStoreProJSObj.ajaxURL,
                    'method': 'POST',
                    'data': {
                        action: orchidStoreProJSObj.ajaxSingleAddToCartAction,
                        form_data: formData
                    },
                    'success': function (response) {

                        if (response.status) {

                            addtoCartButton.data('product_id', productID);

                            $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, addtoCartButton]);

                            // Reset add to cart form
                            if (addToCartForm.hasClass('variations_form')) {
                                let attributeFields = addToCartForm.find('.variations select');
                                attributeFields.val('').trigger('change');
                            }

                            let quantityFields = addToCartForm.find('input.qty');
                            if (quantityFields.length > 0) {
                                $.each(quantityFields, function (key, quantityField) {
                                    let minVal = quantityField.getAttribute('min');
                                    quantityField.value = minVal;

                                    let changeEvent = new Event('change');
                                    quantityField.dispatchEvent(changeEvent);
                                });
                            }

                            if (addToCartForm.hasClass('grouped_form')) {
                                let quantityCheckboxFields = addToCartForm.find('input[type="checkbox"].wc-grouped-product-add-to-cart-checkbox');
                                if (quantityCheckboxFields.length > 0) {
                                    $.each(quantityCheckboxFields, function (key, quantityCheckboxField) {
                                        quantityCheckboxField.checked = false;

                                        let changeEvent = new Event('change');
                                        quantityCheckboxField.dispatchEvent(changeEvent);
                                    });
                                }
                            }

                            addToCartForm.trigger('reset_data');
                        } else {
                            let addedToCartEle = addToCartForm.find('.added_to_cart.wc-forward');
                            if (addedToCartEle.length > 0) {
                                addedToCartEle.remove();
                            }

                            addtoCartButton.removeClass('loading');
                        }

                        // Fire all notices.
                        let notices = response.notices;

                        let wcNoticeWrappers = $('.woocommerce-notices-wrapper');
                        if (wcNoticeWrappers.length > 0) {
                            wcNoticeWrappers.html(notices);
                        }
                    },
                    'error': function (jxhr, status, errorThrown) {
                        throw new Error(errorThrown);
                    },
                });
            });
        }

        if (orchidStoreProJSObj.hasOwnProperty('ajaxBuyNow') && orchidStoreProJSObj.ajaxBuyNow === '1') {

            $(document).on('click', '.os_buy_now', function (event) {

                event.preventDefault();

                let buyNowBtn = $(this);

                buyNowBtn.addClass('loading');

                // Create ajax request for buy now button.
                $.ajax({
                    'url': orchidStoreProJSObj.ajaxURL,
                    'method': 'POST',
                    'data': {
                        action: orchidStoreProJSObj.ajaxBuyNowAction,
                        nonce: orchidStoreProJSObj.nonce,
                        productId: buyNowBtn.attr('data-product_id')
                    },
                    'success': function (response) {
                        // Once success message is received, redirect page to checkout page.

                        if (response.success) {

                            if (orchidStoreProJSObj.hasOwnProperty('redirectURL') && orchidStoreProJSObj.redirectURL !== '') {
                                window.location.replace(orchidStoreProJSObj.redirectURL);
                            }
                        }
                    },
                    'error': function (jxhr, status, errorThrown) {
                        throw new Error(errorThrown);
                    },
                });
            });
        }

        let offset = 0;

        if (orchidStoreProJSObj.hasOwnProperty('ajaxLoadMore') && orchidStoreProJSObj.ajaxLoadMore === '1') {
            let total_products = parseInt(orchidStoreProJSObj.current_page_total_products);
            $(document).on('click', '.os_load_more', function (event) {

                event.preventDefault();

                let loadMoreBtn = $(this);

                loadMoreBtn.addClass('loading');

                let limit = parseInt(orchidStoreProJSObj.number_of_products);
                if (orchidStoreProJSObj.number_of_products) {
                    offset = offset + limit;
                }
                let total_products = parseInt(orchidStoreProJSObj.current_page_total_products);

                if (total_products <= offset) {
                    loadMoreBtn.remove();
                }

                let data = {
                    action: orchidStoreProJSObj.ajaxLoadMoreAction,
                    nonce: orchidStoreProJSObj.nonce,
                    offset: offset,
                    limit: limit
                }

                if (orchidStoreProJSObj.current_page) {
                    switch (orchidStoreProJSObj.current_page) {
                        case 'category':
                            data.current_page = orchidStoreProJSObj.current_page;
                            data.category_id = orchidStoreProJSObj.current_page_id;
                            break;
                        case 'tag':
                            data.current_page = orchidStoreProJSObj.current_page;
                            data.tag_id = orchidStoreProJSObj.current_page_id;
                            break;
                        default:
                            data.current_page = orchidStoreProJSObj.current_page;
                    }
                }


                // Create ajax request for buy now button.
                $.ajax({
                    'url': orchidStoreProJSObj.ajaxURL,
                    'method': 'POST',
                    'data': data,
                    'success': function (response) {

                        if (response.success) {
                            $('ul.products').append(response.data);
                            loadMoreBtn.removeClass('loading');
                        }
                    },
                    'error': function (jxhr, status, errorThrown) {
                        throw new Error(errorThrown);
                    },
                });
            });
        }

    });

})(jQuery);